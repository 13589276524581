import React from "react"
import styled from "styled-components"
import media from "styled-media-query"

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${props => props.height};
  background: ${props => (props.bg ? props.bg : "#FFF")};
`
export const ServicesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${props => props.pad};
  width: 100%;
  background-color: #f8f9f9;
  background-image: url(/images/bgpattern3.png);
  background-position: bottom center;
  background-repeat: repeat-x;
`
export const CenteredContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: 20px;
  max-width: 1140px;
  padding: ${props => props.pad};
  margin: 0 auto;
  justify-items: center;
`
export const H1 = styled.h1`
  font-size: ${props => (props.size ? props.size : "34px")};
  font-family: "Hind", sans-serif;
  font-weight: 600;
  color: ${props => (props.color ? props.color : "#fe7d0b")};
  margin-right: 40px;
  letter-spacing: 0;
  transition: all 0.3s;
  text-transform: ${props => props.transform};
  :hover {
    color: ${props => props.hover};
  }
`
export const H2 = styled.h2`
  font-family: "Hind", Sans-serif;
  font-size: ${props => (props.size ? props.size : "24px")};
  font-weight: 600;
  text-transform: ${props => (props.tt ? props.tt : "uppercase")};
  line-height: 1.44em;
  letter-spacing: 0px;
  color: ${props => (props.color ? props.color : "#FFF")};
`
export const P = styled.p`
  color: ${props => (props.color ? props.color : "#5f656d")};
  font-family: "Open Sans", Sans-serif;
  font-size: ${props => (props.size ? props.size : "15px")};
  font-weight: 400;
  text-transform: none;
  font-style: normal;
  text-decoration: none;
  line-height: 1.643em;
  margin-bottom: 20px;
  letter-spacing: 0;
`
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
export const Divider = styled.hr`
  display: block;
  height: 1px;
  width: 18px;
  border: 0;
  border-top: 3px solid #fe7d0b;
  margin: 1em 0;
  padding: 0;
  margin-top: 10px;
`
export const Img = styled.img`
  object-fit: cover;
`
export const GalleryCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 600px;
  background-image: url(${props => props.url});
  background-position: center;
  background-repeat: no-repeat;

  ${media.lessThan("770px")`
    background-position: 75%;
  `}
`
export const Blur = styled.div`
  backdrop-filter: blur(6px);
  background: rgba(255, 255, 255, 0.2);
  width: 100%;
  height: 100%;
`
export const GalleryCellContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1140px;
  height: 100%;
  box-sizing: border-box;
  margin-bottom: -300px;
  opacity: 0;
  transition: all 0.4s ease;

  ${media.lessThan("770px")`
    justify-content: center;
  `}
`
export const Block = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-image: url(${props => props.url});
  background-repeat: no-repeat;

  ${media.between("1366px", "1600px")`
    background-position: -150%;
  `}

  ${media.between("769px", "1365px")`
    background-position: calc(100% - 280px);
  `}

  ${media.lessThan("768px")`
    background-image: none;
  `}

  ${media.lessThan("700px")`
    div:nth-child(2) {
      display: none;
    }
  `}

  ${media.lessThan("1920px")`
    div:first-child() {
      margin-top: 200px;
    }
  `}
`
export const TextBlock = styled.div`
  display: flex;
  width: 30%;
  max-width: 500px;
  min-width: 350px;
  box-sizing: border-box;
  /* background: red; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 0 50px;

  ${media.lessThan("1920px")`
    width: 50%;
  `}

  ${media.lessThan("700px")`
    padding: 0;
  `}
`
export const CellBlock = styled.div`
  display: flex;
  width: 70%;
  height: 60%;
  background: #ffffffbf;
  box-sizing: border-box;
  padding: 50px 40px;
  justify-content: center;
  align-items: center;
  border-radius: 2px;

  ${media.between("771px", "1024px")`
    img {
      width: 280px;
    }
  `};

  ${media.lessThan("770px")`
    flex-direction: column;
    width: 85%;
    height: 50%;
    padding: 50px 20px;
    img {
      display: none;
    };

    ${media.lessThan("523px")`
      img {
        width: 200px;
        right: 5%;
      }
    `}
  `}
`
export const RoundedIcon = styled.div`
  min-width: 80px;
  width: 80px;
  height: 80px;
  background: #fe7d0b;
  border-radius: 50%;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 20px;
  fill: #fff;
  margin-right: 20px;
`
export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
`
export const Banner = props => (
  <Container {...props}>
    <CenteredContainer>{props.children}</CenteredContainer>
  </Container>
)
