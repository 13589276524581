import styled from "styled-components"
import media from "styled-media-query"

export const Container = styled.div`
  display: flex;
  align-items: center;
  background: #fff;
  width: 100%;
  min-height: 270px;
  box-sizing: border-box;
`
export const Img = styled.img`
  width: 100%;
`
export const Block = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: ${props => (props.align ? props.align : "center")};
`
export const H1 = styled.h1`
  font-size: ${props => (props.size ? props.size : "34px")};
  font-family: "Hind", sans-serif;
  font-weight: 600;
  color: ${props => (props.color ? props.color : "#fe7d0b")};
  letter-spacing: 0;
  margin: ${props => props.margin};
  transition: all 0.3s;

  :hover {
    color: ${props => props.hover};
  }
`
export const H2 = styled.h2`
  font-family: "Hind", Sans-serif;
  font-size: 22px;
  font-weight: 600;
  text-transform: uppercase;
  font-style: normal;
  text-decoration: none;
  line-height: 1.44em;
  letter-spacing: 0px;
  color: ${props => (props.color ? props.color : "#FFF")};
  margin-bottom: 15px;
`
export const H4 = styled.h4`
  font-family: "Hind", Sans-serif;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  font-style: normal;
  text-decoration: none;
  line-height: 1.44em;
  letter-spacing: 0px;
  text-align: center;
  color: ${props => (props.color ? props.color : "#222931")};
`
export const CenteredContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 1140px;
  padding: ${props => props.pad};
  margin: 0 auto;
  height: 100%;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  ${media.lessThan("1140px")`
    padding: 0 30px;
  `};

  ${media.lessThan("600px")`
    flex-direction: column;
    padding: 30px;
  `};
`
export const Icon = styled.div`
  min-width: 40px;
  width: 40px;
  height: 40px;
  margin-top: 10px;
  box-sizing: border-box;
  fill: #222931;

  ${media.lessThan("600px")`
    min-width: 60px;
    width: 60px;
    height: 60px;
  `}
`
export const CountersContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-around;

  ${media.lessThan("600px")`
    flex-direction: column;
    margin-top: 30px;
  `}
`
export const Divider = styled.hr`
  display: block;
  height: 1px;
  width: 18px;
  border: 0;
  border-top: 3px solid #fe7d0b;
  margin: 1em 0;
  padding: 0;
  margin-bottom: 15px;
`
