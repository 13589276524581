import React from "react"
import * as S from "./styles"

export default function Counter({ children }) {
  return (
    <S.Container>
      <S.CenteredContainer>
        <S.Block align="flex-start">
          <S.Divider />
          <S.H1>
            Empresa Nordestina
            <br />
            <br /> Líder de equipamentos de ordenha e produtos de higiene e
            limpeza.
          </S.H1>
        </S.Block>
        <S.CountersContainer>
          <S.Block>
            <S.Icon>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
                <title></title>
                <g id="icomoon-ignore"></g>
                <path d="M32 896h640v32c0 12.8 6.4 22.4 16 28.8s22.4 6.4 32 0l76.8-51.2 76.8 51.2c6.4 3.2 12.8 6.4 19.2 6.4s9.6 0 16-3.2c9.6-6.4 16-16 16-28.8v-176c38.4-35.2 64-86.4 64-144s-25.6-108.8-64-144v-243.2c0-19.2-12.8-32-32-32h-150.4l-217.6-137.6c-6.4-28.8-32-54.4-64-54.4s-57.6 22.4-64 54.4l-214.4 137.6h-150.4c-19.2 0-32 12.8-32 32v640c0 19.2 12.8 32 32 32zM768 419.2c-89.6 16-160 92.8-160 188.8 0 48 19.2 92.8 48 128h-496v-384h608v67.2zM864 867.2l-44.8-32c-9.6-6.4-25.6-6.4-35.2 0l-44.8 32v-3.2c0 0 0 0 0 0s0 0 0 0v-73.6c19.2 6.4 41.6 12.8 64 12.8s44.8-3.2 64-12.8v76.8zM876.8 710.4c0 0-3.2 3.2-3.2 3.2-22.4 12.8-48 22.4-73.6 22.4-70.4 0-128-57.6-128-128s57.6-128 128-128 128 57.6 128 128c0 41.6-19.2 76.8-51.2 102.4zM425.6 115.2c9.6 6.4 22.4 12.8 38.4 12.8s28.8-6.4 38.4-12.8l124.8 76.8h-326.4l124.8-76.8zM64 256h800v172.8c-9.6-3.2-22.4-6.4-32-9.6v-99.2c0-19.2-12.8-32-32-32h-672c-19.2 0-32 12.8-32 32v448c0 19.2 12.8 32 32 32h544v32h-608v-576zM256 512h320c19.2 0 32-12.8 32-32s-12.8-32-32-32h-320c-19.2 0-32 12.8-32 32s12.8 32 32 32zM256 640h192c19.2 0 32-12.8 32-32s-12.8-32-32-32h-192c-19.2 0-32 12.8-32 32s12.8 32 32 32z"></path>
              </svg>
            </S.Icon>
            <S.H1 margin="10px 0">65</S.H1>
            <S.H4>Programa PSMQL</S.H4>
          </S.Block>
          <S.Block>
            <S.Icon>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
                <title></title>
                <g id="icomoon-ignore"></g>
                <path d="M64 192c0 70.592 57.408 128 128 128s128-57.408 128-128-57.408-128-128-128-128 57.408-128 128zM256 192c0 35.296-28.704 64-64 64s-64-28.704-64-64 28.704-64 64-64 64 28.704 64 64zM896 352h-128c-34.24 0-66.464 14.176-90.112 37.664-27.776-23.168-63.008-37.664-101.888-37.664h-128c-38.88 0-74.112 14.496-101.888 37.664-23.648-23.488-55.872-37.664-90.112-37.664h-128c-70.592 0-128 57.408-128 128v192h64v224h224v-64h-160v-224h-64v-128c0-35.296 28.704-64 64-64h128c19.584 0 37.664 9.376 49.696 24.352-10.944 21.664-17.696 45.76-17.696 71.648v256h96v256h256v-256h96v-256c0-25.888-6.752-49.984-17.696-71.648 12.032-14.976 30.112-24.352 49.696-24.352h128c35.296 0 64 28.704 64 64v128h-64v224h-160v64h224v-224h64v-192c0-70.592-57.408-128-128-128zM672 704h-96v256h-128v-256h-96v-192c0-52.928 43.072-96 96-96h128c52.928 0 96 43.072 96 96v192zM704 192c0 70.592 57.408 128 128 128s128-57.408 128-128-57.408-128-128-128-128 57.408-128 128zM896 192c0 35.296-28.704 64-64 64s-64-28.704-64-64 28.704-64 64-64 64 28.704 64 64zM352 160c0 88.224 71.776 160 160 160s160-71.776 160-160-71.776-160-160-160-160 71.776-160 160zM608 160c0 52.928-43.072 96-96 96s-96-43.072-96-96 43.072-96 96-96 96 43.072 96 96z"></path>
              </svg>
            </S.Icon>
            <S.H1 margin="10px 0">1035</S.H1>
            <S.H4>Clientes Felizes</S.H4>
          </S.Block>
        </S.CountersContainer>
      </S.CenteredContainer>
    </S.Container>
  )
}
